import Html from '@components/common/Html';

import classnames from 'classnames/bind';
import styles from './addtionInfo.module.scss';
const cx = classnames.bind(styles);

interface Props {
  question: string;
  answer: string | null;
}

const Text = ({ question, answer }: Props) => {
  if (!answer) return null;

  return (
    <div>
      <h4 className={cx('question')}>{question}</h4>
      <Html html={answer} as="p" className={cx('answer')} />
    </div>
  );
};

export default Text;
